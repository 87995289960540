.main-carte {
  padding-top: 90px;
  font-size: 20px;
  font-weight: 300;
}

.container-carte {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.carte-blog {
  height: 480px;
  width: 300px;
  margin: 15px 10px;
  position: relative;
  transition: all 0.4s;
  border-radius: 10px;
  box-shadow: 2px 11px 36px -16px rgba(0, 0, 0, 0.75);
}
.carte-blog:hover {
  transform: translateY(-10px);
}

.img-carte {
  width: 100%;
  height: 200px;
  object-fit: cover;
  display: block;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.titre-article-blog {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 80%;
  font-weight: 500;
  font-size: 18px;
  padding: 10px;
}

.contenu {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}
.carte-paragraphe {
  text-align: left;
  padding-left: 10px;
  padding-right: 5px;
}
.carte-paragraphe h2 {
font-size: 20px;
}

.btn-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-carte {
  position: absolute;
  bottom: 15px;
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #25ABBE;
  font-size: 14px;
  line-height: 1.125;
  border-radius: 5px;
  padding: 2px 5px;
  cursor: pointer;
}
.linkCardToArticle {
  text-decoration: none;
  color: #f1f1f1;
  font-size: 18px;
  font-weight: 400;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  text-align: center;
  
}
.linkCardToArticle:hover{
    font-weight: 600;
}

.more {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.more-button {
  height: 50px;
  width: 200px;
  border-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #25ABBE;
  color: #f1f1f1;
  font-weight: 500;
  font-size: 20px;
  transition: all 0.4s ease-in-out ;
  overflow: hidden;
}

.more-button:hover {
  transform: scale(1.1);
  border: none;
}

.more-nop{
  display: flex;
  align-items: center;
  justify-content: center;
}
.bad{
  width: 50px;
}

.article-contenu h1 {
  font-size: 24px;
}
.article-contenu h2 {
  font-size: 20px;
}
.article-contenu h3 {
  font-size: 16px;
}
.article-contenu h4 {
  font-size: 14px;
}
.article-contenu h5 {
  font-size: 12px;
}
.article-contenu h6 {
  font-size: 10px;
}
