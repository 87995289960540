@import url('https://fonts.googleapis.com/css2?family=Aref+Ruqaa:wght@400;700&family=Lemonada:wght@300;400;500;700&family=Macondo&display=swap');


.logContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  margin-top: 40px;
}
.titleLog {
  text-align: center;
  margin-bottom: 30px;
  font-size: 25px;
  font-family: "Aref Ruqaa", sans-serif;
}
.containerBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.textToLog {
  padding: 10px;
  font-family: "Aref Ruqaa", sans-serif;
}

.signForm {
  display: flex;
  flex-direction: column;
  align-items: center;
   font-family: "Aref Ruqaa", sans-serif;
}
.signTitle {
  text-transform: uppercase;
  margin-bottom: 20px;
}

.signInp {
  border-radius: 10px;
  height: 50px;
  width: 200px;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-family: "Aref Ruqaa", sans-serif;
}

.signBtn {
  height: 40px;
  outline: none;
  border: none;
  height: 50px;
  width: 180px;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 30px;
  font-weight: bold;
  font-family: "Aref Ruqaa", sans-serif;
}
.logForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Aref Ruqaa", sans-serif;
  margin-top: 200px;
}

.logTitle {
  text-transform: uppercase;
  margin-bottom: 20px;
  
}
.buttonCenter {
  display: flex;
  justify-content: center;
}
.logMail,
.logPass {
  border-radius: 10px;
  height: 50px;
  width: 200px;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-family: "Aref Ruqaa", sans-serif;
}

.logSubmit {
  height: 40px;
  outline: none;
  border: none;
  height: 50px;
  width: 180px;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 30px;
  font-weight: bold;
  font-family: "Aref Ruqaa", sans-serif;
 
}

.btnLog {
  height: 40px;
  outline: none;
  border: none;
  height: 50px;
  width: 110px;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
  font-family: "Aref Ruqaa", sans-serif;
}
.forgotLink {
  margin: 20px 0 10px 0;
}
