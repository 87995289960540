.accueil {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container-acc {
  display: flex;
  width: 80%;
  height: 80%;
  flex-direction: column;
  background-image: url(../../img/felixcropmoy.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.box-moustache {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.box2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.moustache-le-titre {
  width: 100%;
  color: #f1f1f1;
  font-size: 55px;
  font-weight: 100;
  margin: 0;
  padding: 10px;
  letter-spacing: 2px;
  line-height: 1.5;
}
.blog-sous-titre {
  color: #f1f1f1;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  letter-spacing: 2px;
}

.moustache-le-titre,
.bienvenue {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
}

@media screen and (max-width: 870px) {
  .moustache-le-titre {
    font-size: 40px;
  }
}

@media screen and (max-width: 600px) {
  .moustache-le-titre {
    font-size: 30px;
  }
}

@media screen and (max-width: 440px) {
  .bienvenue {
    text-align: center;
    font-size: 16px;
  }
}
