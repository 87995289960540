@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");

.navbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.nav-principal {
  width: 100%;
  height: 100px;
  align-items: center;
  position: fixed;
  background: #fff;
  position: fixed;
  top: 0;
  z-index: 999;
}
.logo-site-lvpa-web {
  position: absolute;
  left: 0px;
  top: 10px;
  width: 100px;
}
.item {
  font-size: 20px;
  list-style-type: none;
  margin: 0 20px;
}

.liste {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-left: 60px;
}

.liste-item {
  line-height: 100px;
}

.texte-nav {
  color: #000;
  text-decoration: none;
  font-family: "Monserrat", sans-serif;
  cursor: pointer;
  font-weight: 100;
  font-size: 18px;
}
@media screen and (max-width: 400px) {
  .texte-nav {
    font-size: 16px;
  }
}

.moustache-titre {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #f1f1f1;
  font-size: 55px;
}
