.editeur-cont {
  width: 100vw;
}

.title-form {
  margin-top: 80px;
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  max-height: 250px;
  min-height: 250px;
}
.editeur {
  margin: auto 20px;
}

.cont-inp-titre {
  display: flex;
  width: 100%;
}
.inp-title {
  margin: 20px;
  height: 25px;
  border-radius: 5px;
  width: 300px;
  height: 30px;
  padding: 0 0 0 5px;
}

.inp-title::placeholder {
  font-weight: 400;
  color: #000;
}

.photo {
  width: 100%;
}

input[type="file"] {
  width: 100%;
}

input[type="file"]::file-selector-button {
  width: 150px;
  margin: 20px;
  color: #f1f1f1;
  border: none;
  border-radius: 10px;
  background-image: linear-gradient(
    126.3deg,
    #1e0253 32.2%,
    rgba(198, 55, 160, 0.46) 109.2%
  );
  padding: 10px;
  cursor: pointer;
}
input[type="file"]::file-selector-button:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.bouton {
  width: 150px;
  margin: 20px;
  color: #f1f1f1;
  border: none;
  border-radius: 10px;
  background-image: linear-gradient(
    126.3deg,
    #1e0253 32.2%,
    rgba(198, 55, 160, 0.46) 109.2%
  );
  padding: 10px;
  cursor: pointer;
}
.bouton:hover {
  transform: scale(1.1);
}
.bouton-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error {
  margin-left: 20px;
  color: red;
}
.message {
  margin-left: 20px;
  color: green;
}
.para1ck {
  color: #1e0253;
}

.test-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.test{
  width: 1000px;
  height: 500px;
  border: 2px solid #e6e6e6;
  border-radius: 5px;
  /* background-color: pink; */
}

