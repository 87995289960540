.article-titre {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  max-width: 1000px;
  font-size: 25px;
  width: 95%;
}


.center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.article-contenu h2,
.article-contenu h3,
.article-contenu h4,
.article-contenu h5,
.article-contenu h6 {
  text-align: left;
  padding-left: 20px;
}

.article-contenu {
  max-width: 1000px;
  border-top: 2px solid #e6e6e6;
  border-bottom: 2px solid #e6e6e6;
  margin-bottom: 100px;
  margin-right: 20px;
  margin-left: 20px;
  padding: 10px;
}

.article-contenu p {
  text-align: justify;
  padding-left: 20px;
  padding-right: 0px;
  margin: 20px 0;
  line-height: 1.3;
}

.article-contenu blockquote {
  margin: 0;
}

.container-image {
  margin-top: 110px;
  width: 90%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.article-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}



@media screen and (max-width: 500px) {
  .article-image {
    width: 95%;
    height: 80%;
  }
}

.article-nul {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: red;
}
button {
  outline: none;
  border: none;
  border-radius: 10px;
  background-color: #25abbe;
  outline: none;
  color: #f1f1f1;
  font-weight: 300;
  width: 100px;
  height: 30px;
  border-radius: 12px;
  padding: 5px;
  transition: all 0.4s;
}
.container-button {
  margin-top: 20px;
}

button:hover {
  border: 1px solid black;
  transform: scale(1.1);
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
}

.article-contenu .auteur {
  margin-top: 30px;
  padding-left: 0px;
}


.button-in-button {
  margin-left: 5px;
  margin-right: 5px;
}
@media screen and (max-width: 500px) {
  .article-contenu {
    width: 95%;
  }
}

@media screen and (max-width: 500px) {
  .center {
    margin: 0;
    padding: 0;
  }
}

@media screen and (max-width: 500px) {
  .button {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 500px) {
  .button-in-button {
    margin-top: 10px;
  }
}

/*! Css de data.js !*/

.lien-data {
  text-decoration: none;
  font-size: 16px;
}

@media screen and (max-width: 500px) {
  .container-image {
    width: 95%;
  }
}
